<template>
  <v-main>
    <v-container fill-height>
      <v-card class="mx-auto d-flex flex-column col-sm-6">
        <v-card-title class="display-1 align-self-center">TS Tool System</v-card-title>

        <v-card-text>
          <v-alert type="error" v-if="error" dense text>{{ error }}</v-alert>
          <v-btn @click="loginWithAzure()" :loading="btnLoginLoading" outlined block>เข้าสู่ระบบด้วย Azure AD</v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { handleException } from "@/util/ExceptionUtil";
import { mapActions } from "vuex";
import router from "../router";
import { loginWithAzure } from "@/core/msal";

export default {
  name: "LoginView",
  inject: ["loadingDialog"],
  data() {
    return {
      showPassword: false,
      formLogin: {
        inputUsername: "",
        inputPassword: "",
        messageAlert: "",
        loading: false,
      },
      btnLoginLoading: false,
      error: null,
    };
  },
  created() {
    sessionStorage.clear();
  },
  methods: {
    async loginWithAzure() {
      this.error = null;
      this.btnLoginLoading = true;
      try {
        await loginWithAzure();
        await this.getUserProfile();
        router.push({ name: "Home" });
      } catch (error) {
        this.btnLoginLoading = false;
        this.formLogin.messageAlert = handleException(err);
      } finally {
        this.btnLoginLoading = false;
      }
    },
    ...mapActions({
      getUserProfile: "user/userProfile",
      getListCompany: "company/getListCompany",
      setNotification: "notification/setSnackbar",
    }),
  },
};
</script>
<style scoped>
.v-main {
  height: 100%;
  background: radial-gradient(circle, var(--v-bgLoginSecondary-base) 0%, var(--v-bgLoginPrimary-base) 100%) !important;
}
</style>
