<template>
    <div>
        <v-navigation-drawer app color="primary" dark v-model="menu" v-if="menu">
            <v-sheet color="secondary" class="pa-4 text-center">
                <v-avatar class="mb-4" color="grey darken-1" size="64"></v-avatar>

                <div>
                    <h3>{{ $t("appName") }}</h3>
                </div>
                <div>{{ fullname }}</div>
            </v-sheet>

            <v-divider></v-divider>

            <v-list>
                <v-list-item v-for="(item, i) in listSideMenu" :key="i" :to="item.path" link>
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app color="primary" dark>
            <v-app-bar-nav-icon @click="menu = !menu"></v-app-bar-nav-icon>

            <v-toolbar-title>{{ $t("appName") }}</v-toolbar-title>

            <v-spacer></v-spacer>
            <span class="mr-1">Version : {{ version }}</span>

            <v-menu transition="slide-y-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" v-if="$vuetify.breakpoint.smAndDown" text>
                        <span class="mr-1 text-truncate" style="max-width: 15vw">{{ fullname }}</span>
                        <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                    </v-btn>

                    <v-btn v-bind="attrs" v-on="on" class="hidden-sm-and-down" v-else text>
                        <span class="mr-2">{{ fullname }}</span>
                        <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item @click="onLogout()" link>
                        <v-list-item-title>{{ listUserMenu[0].name }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { MenuModel } from "../models/MenuModel"
import router from "../router"

export default {
    name: "MainMenu",
    data() {
        return {
            menu: true,
            listSideMenu: [new MenuModel("mdi-home", this.$t("menuName.home"), "/home"), new MenuModel("mdi-hammer-screwdriver", this.$t("menuName.repair"), "/repair")],
            listUserMenu: [new MenuModel("", this.$t("menuName.logout"), "")],
            fullname: this.getUserProfile().fullname,
            version: process.env.VERSION
        }
    },
    methods: {
        ...mapGetters({
            getUserProfile: "user/getUserProfile"
        }),
        ...mapActions({
            logout: "user/logout"
        }),
        async onLogout() {
            await this.logout()
            router.push({ name: "Login" })
        }
    }
}
</script>
