<template>
    <div class="home pa-5">
        <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
        <v-container>
            <v-row>
                <v-col cols="4" lg="4">
                    <v-card class="deepskyblue" evaluation="2">
                        <v-card-text>
                            <v-row align="center" justify="center">
                                <v-col cols="9">
                                    <div class="text-h5">
                                        <animated-number :value="totalUser" :formatValue="defaultFormat" :duration="300" />
                                    </div>
                                    <div class="text-overline">TS Users</div>
                                </v-col>
                                <v-col>
                                    <v-chip color="blue lighten-1" label text-color="white">
                                        <v-icon>mdi-account-multiple</v-icon>
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="4" lg="4">
                    <v-card class="green" evaluation="2">
                        <v-card-text>
                            <v-row align="center" justify="center">
                                <v-col cols="9">
                                    <div class="text-h5">
                                        <animated-number :value="totalCompany" :formatValue="defaultFormat" :duration="300" />
                                    </div>
                                    <div class="text-overline">{{this.$t("company")}}</div>
                                </v-col>
                                <v-col>
                                    <v-chip color="green lighten-1" label text-color="white">
                                        <v-icon>mdi-city</v-icon>
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
// import APIDashboardService from "@/services/APIDashboardService"
import AnimatedNumber from "animated-number-vue"

export default {
    name: "HomeView",
    components: {
        AnimatedNumber
    },
    computed: {
        ...mapGetters({
            totalUser: "dashboard/getTotalUser",
            totalCompany: "dashboard/getTotalCompany",
        })
    },
    watch: {
        "display.totalUser"() {
            clearInterval(this.interval.totalUser)
            if (this.totalUser == this.displayNumber) {
                return
            }
            this.interval.totalUser = window.setInterval(
                function () {
                    if (this.display.totalUser != this.totalUser) {
                        var change = (this.totalUser - this.display.totalUser) / 10

                        change = change >= 0 ? Math.ceil(change) : Math.floor(change)

                        this.display.totalUser = this.display.totalUser + change
                    }
                }.bind(this),
                20
            )
        },
        "display.totalCompany"() {
            clearInterval(this.interval.totalCompany)
            if (this.totalCompany == this.displayNumber) {
                return
            }
            this.interval.totalCompany = window.setInterval(
                function () {
                    if (this.display.totalCompany != this.totalCompany) {
                        var change = (this.totalCompany - this.display.totalCompany) / 10

                        change = change >= 0 ? Math.ceil(change) : Math.floor(change)

                        this.display.totalCompany = this.display.totalCompany + change
                    }
                }.bind(this),
                20
            )
        }
    },
    mounted() {
        this.getTotalUser()
        this.getTotalCompany()
    },
    methods: {
        ...mapActions({
            getTotalUser: "dashboard/getTotalUser",
            getTotalCompany: "dashboard/getTotalCompany"
        }),
        defaultFormat(value) {
            return `${value.toFixed(0)}`
        }
    }
}
</script>

<style scoped>
.v-card {
    background-color: #ffffff !important;
}
.v-card.deepskyblue {
    border-left: 0.3em solid #42a5f5 !important;
}
.v-card.green {
    border-left: 0.3em solid #66BB6A !important;
}
</style>
