import APIService from "./base/BaseAPIService";

export default {
  getTotalUser() {
    return APIService().get("/dashboard/user/total");
  },
  getTotalCompany() {
    return APIService().get("/dashboard/company/total");
  }
};
