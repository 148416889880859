import { APIException } from "@/exception/APIException";
import { CustomException } from "@/exception/CustomException";
import i18n from "@/plugins/i18n";
import router from "@/router/index";
import store from "@/store/index";

function handleException(exception) {
  if (exception instanceof CustomException) {
    return exception.message;
  } else if (exception instanceof APIException) {
    if (exception.status === 401 || exception.status === 403) {
      store.commit("user/SET_USER_PROFILE", { id: 0, username: "", fullname: "", is2FAEnabled: false, is2FAVerify: false });
      router.push({ name: "Login" });
    } else if (exception.status === 404) {
      return i18n.t("msgError.dataNotFound");
    } else {
      return exception.message;
    }
  } else {
    return exception;
  }
}

export { handleException };
