import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import company from "./modules/company";
import notification from "./modules/notification";
import dashboard from "./modules/dashboard";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    user,
    company,
    notification,
    dashboard
  },
  strict: debug,
});
