import APIService from "./base/BaseAPIService";

export default {
  getRepairTaskByComId(companyId, departmentId, locationId, repairTypeId, startDate, endDate) {
    return APIService().get("/repair/company/" + companyId + "/getRepairHistory",
      {
        params: {
          start: startDate,
          end: endDate, 
          depId: departmentId,
          locationId: locationId,
          repairTypeId: repairTypeId
        }
      });
  },
  removeAllRepairHistoryTask(companyId, startDate, endDate, payloads) {
    return APIService().delete("/repair/company/" + companyId + "/delete-repair-history", { params: { start: startDate, end: endDate }, data: payloads });
  },
  removeRepairHistoryTaskBySelected(companyId, payloads) {
    return APIService().delete("/repair/company/" + companyId + "/selected/delete", { data: payloads });
  },
};

