import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";
import HomeView from "../views/HomeView.vue";
import RepairTaskView from "../views/RepairTaskView.vue";
import i18n from "@/plugins/i18n";
import store from "@/store/index";

Vue.use(VueRouter);

const DEFAULT_TITLE = process.env.VUE_APP_TITLE;

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginView,
    meta: { title: i18n.t('login'), requiresAuth: false },
  },
  {
    path: "/home",
    name: "Home",
    component: HomeView,
    meta: { title: i18n.t('menuName.home'), requiresAuth: true },
  },
  {
    path: "/repair",
    name: "Repair",
    component: RepairTaskView,
    meta: { title: i18n.t('menuName.repair'), requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let userProfile = store.getters["user/getUserProfile"]

  if (userProfile.id === 0) {
    await store.dispatch("user/userProfile");
    userProfile = store.getters["user/getUserProfile"];
  }

  if (to.meta.requiresAuth) {
    if (userProfile.id === 0) {
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next()
    }
  } else {
    if (userProfile.id !== 0) {
      next({
        path: "/home",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next()
    }
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title != undefined ? to.meta.title + " - " + DEFAULT_TITLE : DEFAULT_TITLE;
  });
});

export default router;
