import { APIException } from "@/exception/APIException";
import { CompanyModel } from "@/models/CompanyModel";
import { DepartmentModel } from "@/models/DepartmentModel";
import { LocationModel } from "@/models/LocationModel";
import { RepairTypeModel } from "@/models/RepairTypeModel";
import APIMasterService from "@/services/APIMasterService";
import { handleException } from "@/util/ExceptionUtil";

const state = {
  listCompany: [],
  listDepartment: [],
  listLocation: [],
  listRepairType: []
};

const getters = {
  getAllCompany: (state) => {
    return state.listCompany;
  },
  getAllDepartment: (state) => {
    return state.listDepartment;
  },
  getAllLocation: (state) => {
    return state.listLocation;
  },
  getAllRepairType: (state) => {
    return state.listRepairType;
  }

};

const actions = {
  async getListCompany({ commit, state }) {
    if (state.listCompany.length === 0) {
      try {
        await APIMasterService.getListCompany().then((res) => {
          let cvListCompany = [];

          res.data.forEach((company) => cvListCompany.push(new CompanyModel(company.companyId, company.companyName)));

          commit("GET_LIST_COMPANY", cvListCompany);
        }).catch((error) => {
          throw new APIException(error.response.status, error.response.message)
        });
      } catch (err) {
        handleException(err);
      }
    }
  },
  async getListDepartment({ commit }, companyId) {
    try {
      await APIMasterService.getListDepartmentByCompanyId(companyId).then((res) => {
        let cvListDepartment = [];

        res.data.forEach((department) => cvListDepartment.push(new DepartmentModel(department.departmentId, department.departmentName)));

        commit("GET_LIST_DEPARTMENT", cvListDepartment);
      }).catch((error) => {
        console.log(error)
        throw new APIException(error.response.status, error.response.message)
      });
    } catch (err) {
      console.log(err)
      handleException(err);
    }
  },
  async getListLocation({ commit }, companyId) {
    try {
      await APIMasterService.getListLocationByCompanyId(companyId).then((res) => {
        let cvListLocation = [];

        res.data.forEach((location) => cvListLocation.push(new LocationModel(location.locationId, location.locationName)));

        commit("GET_LIST_LOCATION", cvListLocation);
      }).catch((error) => {
        console.log(error)
        throw new APIException(error.response.status, error.response.message)
      });
    } catch (err) {
      console.log(err)
      handleException(err);
    }
  },
  async getListRepairType({ commit }, companyId) {
    try {
      await APIMasterService.getListRepairTypeByCompanyId(companyId).then((res) => {
        let cvListRepairType = [];

        res.data.forEach((repairType) => cvListRepairType.push(new RepairTypeModel(repairType.repairTypeId, repairType.repairTypeName)));

        commit("GET_LIST_REPAIRTYPE", cvListRepairType);
      }).catch((error) => {
        console.log(error)
        throw new APIException(error.response.status, error.response.message)
      });
    } catch (err) {
      console.log(err)
      handleException(err);
    }
  },


};

const mutations = {
  GET_LIST_COMPANY(state, listCompany) {
    state.listCompany = listCompany;
  },
  GET_LIST_DEPARTMENT(state, listDepartment) {
    state.listDepartment = listDepartment;
  },
  GET_LIST_LOCATION(state, listLocation) {
    state.listLocation = listLocation;
  },
  GET_LIST_REPAIRTYPE(state, listRepairType) {
    state.listRepairType = listRepairType;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
