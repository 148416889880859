import { APIException } from "@/exception/APIException";
import APIDashboardService from "@/services/APIDashboardService";
import { handleException } from "@/util/ExceptionUtil";

const state = {
  totalUser: 0,
  totalCompany: 0
};

const getters = {
  getTotalUser: (state) => {
    return state.totalUser;
  },
  getTotalCompany: (state) => {
    return state.totalCompany
  }
};

const actions = {
  async getTotalUser({ commit }) {
    try {
      await APIDashboardService.getTotalUser()
        .then((res) => {
          commit("GET_TOTAL_USER", res.data[0].TOTAL_USER);
        }).catch((error) => {
          throw new APIException(error.response.status, error.response.message)
        });
    } catch (err) {
      handleException(err);
    }
  },
  async getTotalCompany({ commit }) {
    try {
      await APIDashboardService.getTotalCompany()
        .then((res) => {
          commit("GET_TOTAL_COMPANY", res.data[0].TOTAL);
        }).catch((error) => {
          throw new APIException(error.response.status, error.response.message)
        });
    } catch (err) {
      handleException(err);
    }
  }
};

const mutations = {
  GET_TOTAL_USER(state, totalUser) {
    state.totalUser = totalUser;
  },
  GET_TOTAL_COMPANY(state, totalCompany) {
    state.totalCompany = totalCompany;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
