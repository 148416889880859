import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        bgLoginPrimary: colors.indigo,
        bgLoginSecondary: colors.indigo.lighten2,
        primary: colors.indigo,
        secondary: colors.indigo.darken3,
      },
    },
    light: true,
  },
});
