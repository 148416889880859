<template>
    <v-dialog v-model="show" max-width="300" persistent>
        <v-card>
            <v-card-title>{{ msg }}</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="show = false">{{ $t("confirmDialog.no")}}</v-btn>
                <v-btn color="primary" text @click="onClick()">
                    <b>{{ $t("confirmDialog.yes")}}</b>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ConfirmDialog",
    props: {
        value: Boolean,
        msg: String,
        onConfirm: {
            type: Function,
            required: true
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit("input", value)
            }
        }
    },
    methods: {
        async onClick() {
            if (this.onConfirm && typeof this.onConfirm === "function") {
                await this.onConfirm()
            }
        }
    }
}
</script>
