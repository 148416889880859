import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "../assets/lang/en"
import th from "../assets/lang/th"

Vue.use(VueI18n);

const messages = {
  en: en,
  th: th,
};

const i18n = new VueI18n({
  locale: "th", // set locale
  fallbackLocale: "en", // set fallback locale
  messages, //set locale messages
});

export default i18n;
