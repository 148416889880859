import APIService from "./base/BaseAPIService";

export default {
  getListCompany() {
    return APIService().get("/master/get-company");
  },
  getListDepartmentByCompanyId(companyId) {
    return APIService().get("/master/company/" + companyId + "/departments");
  },
  getListLocationByCompanyId(companyId) {
    return APIService().get("/master/company/" + companyId + "/locations");
  },
  getListRepairTypeByCompanyId(companyId) {
    return APIService().get("/master/company/" + companyId + "/repairTypes");
  },

  getOtp() {
    return APIService().post("/master/getOtp");
  },
};
