<template>
    <v-app>
        <MainMenu v-if="onCheckUserProfile()" />
        <LoadingDialog v-model="showLoadingDialog.val" />

        <!-- Notification -->
        <v-snackbar v-for="(snackbar, index) in snackbars.filter((s) => s.showing)" transition="slide-x-transition" :key="snackbar.text + index + Math.random()" :timeout="snackbar.timeout" v-model="snackbar.showing" :color="snackbar.color" :style="`bottom: ${index * 60}px`">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="removeNotification(snackbar)" v-bind="attrs">mdi-close-circle</v-icon>
            </template>
        </v-snackbar>
        <!-- Notification -->

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import MainMenu from "@/components/MainMenu"
import LoadingDialog from "@/components/dialog/LoadingDialog"
import { mapActions, mapGetters } from "vuex"

export default {
    name: "App",
    components: {
        MainMenu,
        LoadingDialog
    },
    data: () => ({
        showLoadingDialog: {
            val: false
        },
        timeout: 6000
    }),
    provide() {
        return {
            loadingDialog: this.showLoadingDialog
        }
    },
    computed: {
        ...mapGetters({
            snackbars: "notification/getSnackbars",
            getUserProfile: "user/getUserProfile"
        })
    },
    methods: {
        ...mapActions({
            removeNotification: "notification/removeSnackbar"
        }),
        onCheckUserProfile() {
            let userProfile = this.getUserProfile
            if (userProfile !== undefined) {
                if (userProfile.is2FAEnabled) {
                    if (userProfile.id !== 0 && userProfile.is2FAVerify) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    if (userProfile.id !== 0) {
                        return true
                    } else {
                        return false
                    }
                }
            } else {
                return false
            }
        }
    }
}
</script>

