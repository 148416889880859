const state = {
  snackbars: [],
};

const getters = {
  getSnackbars: (state) => {
    return state.snackbars;
  },
};

const actions = {
  setSnackbar({ commit }, snackbar) {
    snackbar.showing = true;
    snackbar.color = snackbar.color || "success";
    snackbar.timeout = snackbar.timeout || 6000;
    commit("SET_SNACKBAR", snackbar);
    setTimeout(() => {
      commit("REMOVE_SNACKBAR", snackbar);
    }, snackbar.timeout);
  },
  removeSnackbar({ commit }, snackbar) {
    commit("REMOVE_SNACKBAR", snackbar);
  },
};

const mutations = {
  SET_SNACKBAR(state, snackbar) {
    state.snackbars = state.snackbars.concat(snackbar);
  },
  REMOVE_SNACKBAR(state, snackbar) {
    state.snackbars = state.snackbars.filter((s) => {
      return s !== snackbar;
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
